import moment from "moment";
import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore, useAuthStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";
import { mock_sleep } from "../../../utils/global";

/**
 *
 * @param {{year: number, month: number, search : string}} Object
 * @returns
 */
export const _api_get_timeline = ({ year, month, search, role }) => {
    return fetcher(
        `${BASE_URL_API}/leave/year/${year}/month/${
            month + 1
        }/planning/${role}?q=${search}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

export const api_get_leaves_list = (id_user) => {
    if (!id_user) {
        return fetcher(`${BASE_URL_API}/leave_type/list`, {
            method: "GET",
            credentials: "include",
        });
    } else {
        return fetcher(`${BASE_URL_API}/user/${id_user}/leave_type/list`, {
            method: "GET",
            credentials: "include",
        });
    }
};

// export const getAnnuaire = async (query) => {
//     const rest = query !== "" ? "?q=" + query : "";
//     let url = BASE_URL_API + "/company/directory" + rest;
//     return fetcher(url, {
//         method: "GET",
//         credentials: "include",
//     });
// };

export const api_get_leaves_list_for_manager = async () => {
    return fetcher(`${BASE_URL_API}/leave/user/list`, {
        method: "GET",
        credentials: "include",
    });
};

//team balance
export const get_team_balance = (forRh) => {
    // console.log({ forRh });

    const url =
        forRh === true
            ? `${BASE_URL_API}/company/balances/details`
            : `${BASE_URL_API}/team/balances`;

    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};
export const api_get_events = () => {
    const { id } = useAuthDetailStore.getState().detail;
    return fetcher(`${BASE_URL_API}/user/${id}/leave/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const api_add_new_leave = ({
    leave_user,
    LeaveType,
    leave_type_id,
    start_date,
    start_time,
    end_date,
    end_time,
    comment,
    uploaded_files,
    negative_balance_alert_accepted,
}) => {
    const { id } = useAuthDetailStore.getState().detail;
    const url = `${BASE_URL_API}/user/${
        leave_user ? leave_user : id
    }/leave/request`;
    return fetcher(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            leave_user,
            LeaveType,
            leave_type_id,
            start_date,
            start_time,
            end_date,
            end_time,
            comment,
            uploaded_files,
            negative_balance_alert_accepted,
        }),
    });
};

export const _api_cancel_leave = (data) => {
    // return mock_sleep();
    const { id: id_user } = useAuthDetailStore.getState().detail;
    // console.log({ data });

    return fetcher(
        `${BASE_URL_API}/user/${id_user}/leave_request/${
            data?.leave_request_id ?? data
        }/delete`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const api_balances_detail = () => {
    const detail = useAuthDetailStore.getState().detail;
    return fetcher(`${BASE_URL_API}/user/${detail.id}/leave/balances`, {
        method: "GET",
        credentials: "include",
    });
};

export const api_balances_detail_timeline = ({ id_user }) => {
    if (!id_user) {
        return;
    }
    return fetcher(`${BASE_URL_API}/user/${id_user}/leave/balances`, {
        method: "GET",
        credentials: "include",
    });
};

// mes demandes d'absences en tant que manager
export const api_personal_calendar_detail = (month, year) => {
    if (!month || !year) {
        return;
    }
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/leave/list?all=1&m=${month}&y=${year}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const api_personal_list_leave = ({ page = 1, ...rest }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/leave/list?page=${page}&${rest.params_string}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

export const manager_pending_leaves = () => {
    return fetcher(`${BASE_URL_API}/team/balances-to-approve-count`, {
        method: "GET",
        credentials: "include",
    });
};

// mes demandes d'absences en tant que manager
export const api_team_calendar_detail = (month, year) => {
    if (!month || !year) {
        return;
    }
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/my_team/leaves?all=1&m=${month}&y=${year}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const _get_total_team_balance = () => {
    return fetcher(`${BASE_URL_API}/team/total_balances`, {
        method: "GET",
        credentials: "include",
    });
};

// get and post ajustement soldes Feature for manager
export const _adjustement_employee_solde = ({ data }) => {
    const { id_user, leave_type_id, operation, balance_value, comment } = data;

    return fetcher(
        `${BASE_URL_API}/user/${id_user}/leave/${leave_type_id}/balance/edit`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                id_user,
                leave_type_id,
                operation,
                balance_value: parseFloat(balance_value.replace(",", ".")),
                comment,
            }),
        }
    );
};

/**
 *
 * @param {{id_user: number, leave_request_id : number}} Params
 * @returns
 */
export const api_remind_leave = ({ id_user, leave_request_id }) => {
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/leave_request/${leave_request_id}/remind`,
        {
            method: "POST",
            credentials: "include",
        }
    );
};
export const _api_get_list_history_ajustement = ({ page = 1, ...rest }) => {
    console.log({ rest });
    return fetcher(
        `${BASE_URL_API}/balances/adjustment?page=${page}&${rest.params_string}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const _api_get_list_leave_as_manager = ({ page = 1, ...rest }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/my_team/leaves?page=${page}&${rest.params_string}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

//ajusteùent des soldes
export const _get_adjustement_user = ({ id_user }) => {
    console.log("toutou", id_user);
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/adjustable_leave_type/list`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

//export
export const _get_types_leave = () => {
    return fetcher(`${BASE_URL_API}/company/leave_type/filters`, {
        method: "GET",
        credentials: "include",
    });
};

// pour filter
export const _get_types_leave_for_filter = () => {
    return fetcher(`${BASE_URL_API}/leave/leave_type/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_refuser_leave_request = ({
    leave_user_id,
    leave_request_id,
}) => {
    return fetcher(
        `${BASE_URL_API}/user/${leave_user_id}/leave_request/${leave_request_id}/reject`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const _api_approve_leave_request = ({
    leave_user_id,
    leave_request_id,
    approval_type,
}) => {
    return fetcher(
        `${BASE_URL_API}/user/${leave_user_id}/leave_request/${leave_request_id}/approve`,
        {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ approval_type }),
        }
    );
};

export const _api_delete_leave_request = ({
    leave_user_id,
    leave_request_id,
}) => {
    return fetcher(
        `${BASE_URL_API}/user/${leave_user_id}/leave_request/${leave_request_id}/delete`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const _api_approve_or_refuse_leave = ({ id, leave_user_id, config }) => {
    return fetcher(
        `${BASE_URL_API}/user/${leave_user_id}/leave_request/${id}/${config.type}`,
        {
            method: config.method,
            credentials: "include",
        }
    );
};
export const allSocietyLeave = ({ page = 1, ...rest }) => {
    // const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/company/leaves?page=${page}&${rest.params_string}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const LeaveAllSociety = (month, year) => {
    // const { id: id_user } = useAuthDetailStore.getState().detail;
    if (!month || !year) {
        return;
    }
    return fetcher(
        `${BASE_URL_API}/company/leaves?all=1&m=${month}&y=${year}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const myProjectionCalcul = (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/balance_simulator/year/${data?.code_year}/month/${data?.code_month}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const api_holidays_list = () => {
    return fetcher(`${BASE_URL_API}/holidays/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_download_leave_attachment = async ({ leave_id }) => {
    const user = useAuthStore.getState().infos;
    let filename = "";
    return fetch(`${BASE_URL_API}/leave/${leave_id}/attachment/download`, {
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
        credentials: "include",
    })
        .then((res) => {
            filename = res.headers
                .get("Content-Disposition")
                .split(";")
                .at(1)
                .trim()
                .split("=")
                .at(1)
                .replaceAll('"', "");
            return res.blob();
        })
        .then((data) => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = filename;
            a.click();
        });
};

export const ExportAllSoldeForRH = async (data) => {
    console.log({ data55: data });
    const user = useAuthStore.getState().infos;
    if (!user) throw new Error("User not authenticated");
    const start_date = moment(data?.start_date).format("YYYY-MM-DD");

    const ud = data?.employee?.length
        ? data.employee.map((u) => `ud=${u.id}`).join("&")
        : "";
    const t = data?.type?.length
        ? data.type.map((t) => `t=${t.id}`).join("&")
        : "";

    // const removeAccents = (str) => {
    //     return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    // };

    // const t = data?.type?.length
    //     ? data.type.map((t) => `t=${removeAccents(t.header)}`).join("&")
    //     : "";

    // Construction de l'URL sans ajouter '&' inutilement
    let url = `${BASE_URL_API}/company/balances/export`;

    // Ajout des paramètres si présents
    const params = [];
    if (t) params.push(t);
    if (ud) params.push(ud);

    if (params.length > 0) {
        url += `?${params.join("&")}`;
    }

    // return console.log({ url });
    try {
        const res = await fetch(url, {
            headers: {
                Authorization: `Bearer ${user?.access}`,
            },
            credentials: "include",
        });

        if (!res.ok) {
            throw new Error(`Error: ${res.status} ${res.statusText}`);
        }

        const contentDisposition = res.headers.get("Content-Disposition");
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = "user_format_export.xlsx"; // Default filename
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }

        const blob = await res.blob();
        const objectUrl = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = objectUrl;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
    } catch (error) {
        console.error("Error fetching or processing data:", error);
        // Handle error as needed
    }
};

export const Export_data_for_manager = async (data) => {
    console.log({ tata: data });
    const user = useAuthStore.getState().infos;
    if (!user) throw new Error("User not authenticated");

    const start_date = moment(data?.start_date).format("YYYY-MM-DD");
    const end_date = moment(data?.end_date).format("YYYY-MM-DD");

    const ud = data?.employee?.length
        ? data.employee.map((u) => `ud=${u.id}`).join("&")
        : "";
    const s = data?.checkboxes_status?.length
        ? data.checkboxes_status.map((cb) => `s=${cb}`).join("&")
        : "";
    const t = data?.type?.length
        ? data.type.map((t) => `t=${t.id}`).join("&")
        : "";

    let url = `${BASE_URL_API}/company/leaves/export?start_date=${encodeURIComponent(
        start_date
    )}&end_date=${encodeURIComponent(end_date)}`;

    if (ud) url += `&${ud}`;
    if (s) url += `&${s}`;
    if (t) url += `&${t}`;

    try {
        const res = await fetch(url, {
            headers: {
                Authorization: `Bearer ${user?.access}`,
            },
            credentials: "include",
        });

        if (!res.ok) {
            throw new Error(`Error: ${res.status} ${res.statusText}`);
        }

        const contentDisposition = res.headers.get("Content-Disposition");
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = "user_format_export.xlsx"; // Default filename
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }

        const blob = await res.blob();
        const objectUrl = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = objectUrl;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
    } catch (error) {
        console.error("Error fetching or processing data:", error);
        // Handle error as needed
    }
};
