import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../common/styles";
import CustomBreadCrumb from "../../components/breadcrumb";
import { NAV_LIST, TABS_ONBOARDING } from "./constant";
import {
    AddIcon,
    Button,
    Flex,
    Loader,
    ParticipantAddIcon,
} from "@fluentui/react-northstar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import SubHeader from "../../components/breadcrumb/SubHeader";
import MainContentOnboarding from "./components/MainContentOnboarding";
import { _getAllAgencies } from "./api";
import { useQuery } from "react-query";

export default function NewOnboarding() {
    const navigate = useNavigate();

    const [tabView, setTabView] = useState(null);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        console.log("je suis dans le useEffect");
        const param_tab = params.get("tab");
        if (param_tab) {
            setTabView(Number(param_tab));
        }
    }, []);

    const onChangeSliderTab = (value) => {
        setTabView(value);
        setParams({
            tab: value,
        });
        return;
    };

    const { isLoading, data: agencies } = useQuery(
        ["agencys"],
        () => _getAllAgencies(),
        {
            onSuccess: (data) => {
                if (!tabView) {
                    return setTabView(data[0]?.id);
                }
            },
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading) {
        return <Loader label="Chargement de la page ....." />;
    }

    const menuItems = agencies?.map((agency) => ({
        content: agency.name,
        value: agency.id,
        action_count: agency?.action_count,
    }));

    return (
        <ContainerFluid fill column>
            <CustomBreadCrumb
                navList={NAV_LIST}
                actions={
                    <Button
                        content={"Ajouter un collaborateur"}
                        icon={<ParticipantAddIcon outline />}
                        iconPosition="after"
                        onClick={() =>
                            navigate(`${ROUTES.employee_add}?refer=onboarding`)
                        }
                        flat
                    />
                }
            />
            {agencies && (
                <>
                    <SubHeader
                        menu={menuItems}
                        setTabView={onChangeSliderTab}
                        defaultSelectedValue={tabView}
                        style={{
                            backgroundColor: "white",
                        }}
                    />

                    <Flex fill>
                        <MainContentOnboarding AgencyId={tabView} />
                    </Flex>
                </>
            )}
        </ContainerFluid>
    );
}
