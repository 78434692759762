export const ROUTES = {
    login: "/login",
    home: "/dashboard",
    task_manager: "/dashboard/tasks",
    leave: "/leave",
    rh_document: "/hr/documents",
    annuaire: "/annuaire",
    employee_add: "/employee/add",
    employee_folder: {
        route: `/employee/:id_employee`,
        custom_route: function (id_employee) {
            return this.route.replace(":id_employee", id_employee);
        },
    },
    expenses: {
        home: "/expenses",
        detail: {
            route: "detail/:id_expense",
            custom_route: (route, id_expense = null) => {
                return route.replace(":id_expense", id_expense);
            },
        },
        add_expense: {
            route: "add/:id_expense",
            custom_route: (route, id_expense = null) => {
                return route.replace(":id_expense", id_expense);
            },
        },
        add_form: {
            route: "add/:id_expense/category/:id_category/:type_form",
            custom_route: (
                route,
                id_expense = null,
                id_category = null,
                type_form = null
            ) => {
                return route
                    .replace(":id_expense", id_expense)
                    .replace(":id_category", id_category)
                    .replace(":type_form", type_form);
            },
        },
        update_form: {
            route: "/expenses/update/report/:id_report/expense/:id_expense/category/:type_form",
            custom_route: function (
                id_report = null,
                id_expense = null,
                type_form = null
            ) {
                return this.route
                    .replace(":id_report", id_report)
                    .replace(":id_expense", id_expense)
                    .replace(":type_form", type_form);
            },
        },
        my_history: {
            route: "history",
        },
        detail_status_expense: {
            route: "/expenses/report/:id_report/detail",
            custom_route: function (id_report) {
                return this.route.replace(":id_report", id_report);
            },
        },
        accounting_manager: {
            home: {
                route: "accounting_manager/home",
            },
            detail: {
                route: "/expenses/accounting_manager/:id_user/report/:id_report/detail",
                custom_route: function (id_report, id_user) {
                    return this.route
                        .replace(":id_report", id_report)
                        .replace(":id_user", id_user);
                },
            },
        },
        manager: {
            home: {
                route: "my_team/view",
            },
            detail: {
                route: "/expenses/my_team/:id_user/report/:id_report/detail",
                custom_route: function (id_report, id_user) {
                    return this.route
                        .replace(":id_report", id_report)
                        .replace(":id_user", id_user);
                },
            },
            history: {
                route: "/expenses/my_team/view/history",
            },
        },
    },
    time_reporting: {
        home: "/time_reporting",
        week: "/time_reporting/week",
        week_rh: "/time_reporting/week_rh",
        annual_tracking: "/time_reporting/annual_tracking",
        team_tracking: "/time_reporting/team_tracking",
        entreprise: "/time_reporting/entreprise",
    },
    remote_job: {
        home: "/remote_job",
    },
    onboarding: {
        home: "/onboarding",
        detail: "/onboarding/detail/:id_task",
        suivi: "/onboarding/suivi/:id_user",
    },
    forget_password: "/forget_password",
    detail_task: "/detail_task",
    teams: "/teams",
    not_found: "/404",
};
